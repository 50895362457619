// Styles for the next and back buttons
// Include these styles BEFORE the layout styles and any project-specific overwrites

.lead-constructor__next {
  max-width: $max-button-width;
  width: 100%;

  svg {
    padding-left: $grid-gutter/2;
    vertical-align: -1px; }
  .animated-arrow-2, .animated-arrow-3 {
    svg {
      padding-left: 0px; } } }


.lead-constructor__back {
  display: block;
  margin-top: $grid-gutter;
  text-decoration: underline;

  svg {
    padding-right: $grid-gutter/4;
    vertical-align: -1px; } }
