// Styles for the leadform/panel transitions and animations
// Include these styles BEFORE the layout styles and any project-specific overwrites

$animation-speed: .15s;

@keyframes simple-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-in {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

.active {
  .lead-constructor__back {
    animation: $animation-speed*2 ease-in-out both simple-fade; }

  // Slide in the questions from right to left with a quick fade
  .slide-question,
  .slide-subheading {
    animation: $animation-speed*2 ease-in-out both slide-in, $animation-speed linear both simple-fade; }

  // Progressively fade in each button
  .fade-buttons {
    .lead-constructor__button {
      animation: $animation-speed ease-in-out both simple-fade;

      &:nth-of-type(1) {
        animation-delay: $animation-speed*2; }
      &:nth-of-type(2) {
        animation-delay: $animation-speed*3; }
      &:nth-of-type(3) {
        animation-delay: $animation-speed*4; }
      &:nth-of-type(4) {
        animation-delay: $animation-speed*5; }
      &:nth-of-type(5) {
        animation-delay: $animation-speed*6; }
      &:nth-of-type(6) {
        animation-delay: $animation-speed*7; }
      &:nth-of-type(7) {
        animation-delay: $animation-speed*8; }
      &:nth-of-type(8) {
        animation-delay: $animation-speed*9; }
      &:nth-of-type(9) {
        animation-delay: $animation-speed*10; } } }

  // Simple fade in, used on the contact details panel for now
  .fade-in {
    animation: $animation-speed ease-in-out $animation-speed*2 both simple-fade; } }

.complete {
  // For the complete state, fade out the entire panel
  // And then flip the visibility back to hidden and display to none after the fade
  &.lead-constructor__panel {
    transition: opacity $animation-speed linear, visibility 0s $animation-speed linear, display 0s $animation-speed linear; } }

// When stepping back, do a simple fade on all elements
.lead-constructor[data-animation-direction='back'] {
  .active {
    .lead-constructor__back {
      animation: $animation-speed ease-in-out both simple-fade; }

    .slide-question,
    .slide-subheading {
      animation: $animation-speed ease-in-out both simple-fade; }

    .fade-buttons {
      .lead-constructor__button {
        animation-delay: 0s; } } } }

.animated-arrow {
  opacity: 1;
  display: inline-block;
  width: 15px;
  animation: slide 1.5s ease-in-out infinite; }

.edge-button--loading {
  background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-position: -200% 0;
  background-size: 50% 100%;
  background-repeat: no-repeat;
  animation: 1.5s loading-button infinite linear; }

@keyframes slide {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  70% {
    opacity: 1; }
  100% {
    opacity: 0; }
  30%,
  100% {
    transform: translate(15px, 0); } }

@keyframes loading-button {
  0% {
    background-position: -200% 0; }
  100% {
    background-position: 250% 0; } }
