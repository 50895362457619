// Basic panel showing and hiding, hidden by default
// Optional: use in conjunction with `basics/animations`

.lead-constructor__panel {
  display: none;
  opacity: 0;
  padding: 0;
  visibility: hidden;

  @include media($width-l) {
    margin: 0 auto;
    text-align: left; }

  &.active {
    visibility: visible;
    opacity: 1;
    display: block; }

  &.complete {
    visibility: hidden;
    opacity: 0;
    display: none; } }
