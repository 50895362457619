body {
  margin: 0; }

.vt-leadform-wizard {

  // Variables
  @import '../variables/buttons';

  // Principles
  @import '~vt-frontend-engine/app/styles/principles/variables/colors';
  @import '~vt-frontend-engine/app/styles/principles/variables/edge-grid';
  @import '~vt-frontend-engine/app/styles/principles/variables/edge-typography';
  @import '~vt-frontend-engine/app/styles/principles/variables/media-queries';
  @import '~vt-frontend-engine/app/styles/principles/variables/motion';
  @import '~vt-frontend-engine/app/styles/principles/mixins/type';
  @import '~vt-frontend-engine/app/styles/principles/mixins/media';
  @import '~vt-frontend-engine/app/styles/principles/mixins/em';
  @import '~vt-frontend-engine/app/styles/principles/utility-classes/text';

  // Basics
  @import '~vt-frontend-engine/app/styles/basics/forms';
  @import '~vt-frontend-engine/app/styles/basics/edge-heading';
  @import '../overwrites/wizard/basics/edge-button';
  @import '../basics/animations';
  @import '../basics/lead-constructor-panel';
  @import '../components/basics/buttons';

  // Components
  @import '~vt-frontend-engine/app/styles/components/form-field';
  @import '../components/layouts/wizard';

  font-family: $font-primary, sans-serif; }
