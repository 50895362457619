// Styles for all general, shared styles for all wizard leadforms
// Include these styles AFTER any /basics styles, and BEFORE any template-specific
// and project-specific overwrites

.leadform--wizard {
  background: linear-gradient(color(white) 0%, edge-color(background-light-blue) 100%);
  text-align: center;
  overflow: hidden;
  position: relative;

  @include media($width-l) {
    min-height: 500px; }

  > svg {
    margin-bottom: -4px;
    max-width: 480px;
    transition: opacity .3s ease-out, margin-left .3s ease-out;
    max-height: 210px;

    @include media($width-l) {
      bottom: 0;
      height: auto;
      left: 50%;
      margin-bottom: 0;
      margin-left: -312px;
      max-width: 625px;
      position: absolute;
      width: 100%;
      z-index: 0; } }

  &.interacted > svg {
    opacity: 0.4;

    @include media($width-l) {
      margin-left: -70px; } } }

.lead-constructor__panel {
  padding: 0 $grid-gutter*2;

  @include media($width-l) {
    height: 500px;
    max-width: 960px; } }

.lead-constructor__panel--split-text {
  display: block;
  padding: 0 $grid-gutter;
  text-align: center;
  @include media($width-l) {
    display: none; }

  > .lead-constructor__heading--split-text {
    margin-top: $grid-gutter; } }

.lead-constructor__eyebrow--split-text {
  margin: 10px 0;
  @include type(18, 24); }

.lead-constructor__heading--split-text {
  @include type(24, 36);
  @include media($width-l) {
    @include type(20, 28); } }

.lead-constructor__subheading--split-text {
  display: none;
  @include media($width-l) {
    display: block; } }

.lead-constructor__content {
  z-index: 10;

  // vertically center the content
  @include media($width-l) {
    position: relative;
    top: 40%;
    transform: translateY(-50%); } }

.lead-constructor__question-block {
  margin: 0 auto $grid-gutter;

  > .lead-constructor__heading--split-text {
    display: none;
    @include media($width-l) {
      display: block; } }

  > .lead-constructor__subheading.lead-constructor__subheading--split-text {
    display: block;
    margin-bottom: $grid-gutter;
    @include media($width-l) {
      display: none; } } }

.lead-constructor__question {
  @include type(20, 28);
  @include media($width-l) {
    @include type(32, 39);
    text-align: left; } }

.leadform--footer-logo {
  margin: 30px 0;
  text-align: center; }

.lead-constructor__why-we-ask {
  color: color(gray);
  margin-left: auto;
  margin-right: auto;
  margin-top: $grid-gutter*2;
  max-width: 400px;
  @include type(14,20);
  @include media($width-l) {
    text-align: left;
    margin-left: 0;
    margin-right: 0; }
  p {
    margin: 0; } }

.leadform--overlay {
  .leadform-responsive-image--mobile {
    height: 360px;
    @include media($width-l) {
      height: 500px; } }

  .leadform-responsive-image--mobile {
    object-position: unset;
    @include media($width-m) {
      object-position: 70% 50%; } }

  .leadform-responsive-picture + * {
    position: static; }

  .image-overlay {
    background: rgba(0, 0, 0, 0.25);
    height: 360px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    @include media($width-l) {
      display: none; } }

  &.interacted .image-overlay {
    display: none; }

  .lead-constructor__panel--welcome {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 360px;
    bottom: 0;
    left: 0;
    right: 0;
    @include media($width-l) {
      position: static;
      display: block;
      height: 500px; }

    &.complete {
      @include media($width-l) {
        height: 0; } } } }

.leadform--overlay {
  .lead-constructor__panel--welcome {
    .lead-constructor__content,
    .lead-constructor__eyebrow,
    .lead-constructor__heading,
    .lead-constructor__subheading,
    .guarantee {
      color: color(white);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; } } }

